export const FETCH_REPORTANALYSISFORMCOUNT_REQUEST = "FETCH_REPORTANALYSISFORMCOUNT_REQUEST";
export const FETCH_REPORTANALYSISFORMCOUNT_SUCCESS = "FETCH_REPORTANALYSISFORMCOUNT_SUCCESS";
export const FETCH_REPORTANALYSISFORMCOUNT_ERROR = "FETCH_REPORTANALYSISFORMCOUNT_ERROR";

export const FETCH_REPORTANALYSISCONSUMERLIST_REQUEST = "FETCH_REPORTANALYSISCONSUMERLIST_REQUEST";
export const FETCH_REPORTANALYSISCONSUMERLIST_SUCCESS = "FETCH_REPORTANALYSISCONSUMERLIST_SUCCESS";
export const FETCH_REPORTANALYSISCONSUMERLIST_ERROR = "FETCH_REPORTANALYSISCONSUMERLIST_ERROR";

export const FETCH_REPORTANALYSISSTAFFLIST_REQUEST = "FETCH_REPORTANALYSISSTAFFLIST_REQUEST";
export const FETCH_REPORTANALYSISSTAFFLIST_SUCCESS = "FETCH_REPORTANALYSISSTAFFLIST_SUCCESS";
export const FETCH_REPORTANALYSISSTAFFLIST_ERROR = "FETCH_REPORTANALYSISSTAFFLIST_ERROR";

export const FETCH_REPORTANALYSISHAZARDLIST_REQUEST = "FETCH_REPORTANALYSISHAZARDLIST_REQUEST";
export const FETCH_REPORTANALYSISHAZARDLIST_SUCCESS = "FETCH_REPORTANALYSISHAZARDLIST_SUCCESS";
export const FETCH_REPORTANALYSISHAZARDLIST_ERROR = "FETCH_REPORTANALYSISHAZARDLIST_ERROR";

export const FETCH_REPORTANALYSISINFECTIONLIST_REQUEST = "FETCH_REPORTANALYSISINFECTIONLIST_REQUEST";
export const FETCH_REPORTANALYSISINFECTIONLIST_SUCCESS = "FETCH_REPORTANALYSISINFECTIONLIST_SUCCESS";
export const FETCH_REPORTANALYSISINFECTIONLIST_ERROR = "FETCH_REPORTANALYSISINFECTIONLIST_ERROR";


export const FETCH_REPORTANALYSISFEEDBACKLIST_REQUEST = "FETCH_REPORTANALYSISFEEDBACKLIST_REQUEST";
export const FETCH_REPORTANALYSISFEEDBACKLIST_SUCCESS = "FETCH_REPORTANALYSISFEEDBACKLIST_SUCCESS";
export const FETCH_REPORTANALYSISFEEDBACKLIST_ERROR = "FETCH_REPORTANALYSISFEEDBACKLIST_ERROR";

