import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import locker from "../../assets/images/icons/wired-lock.gif";
import "./OtpStyle.css";
import { useDispatch } from "react-redux";
import { userVerifyOtp } from "../../store/actions/authAction";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
const Otp = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const handleOtp = async (e) => {
    setOtp(e);
  };

  const verifyOtp = async (e) => {
    // SQ JP - 18 July, 23
    // TODO: Check if the OTP length is 6 digits
    // Make API call to verify OTP
    e.preventDefault();
    console.log("otp ", otp);
    await dispatch(
      // TODO: Change this hardcoded value to dynamic
      userVerifyOtp(otp, (res) => {
        if (res.success) {
          toast.success(res.message);
          // SQ -JP, 5 Jun, 23 - Showing user forms as default page
          history.push(
            "/" + res.data.companyName.replace(/\s+/g, "-") + "/forms"
          );
        } else {
          toast.error(res.message);
          // history.push("/user/login");
        }
      })
    );
  };

  const redirectHomeBtn = () => {
    history.push("/user/login");
  };
  return (
    <div className="wrapper">
      <div className="otp-card">
        <div className="row">
          <div className="col-sm-12">
            <img src={locker} className="img img-fluid" alt="Locker" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h3 className="text-center textStyle">
              Enter 6 digits verification code sent to your email
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mt-1">
            <div className="d-flex justify-content-center">
              <OtpInput
                value={otp}
                onChange={handleOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                // inputStyle={"form-control mx-1 text-danger inputBox"}
                inputStyle={{
                  color: "black",
                  width: "35px",
                  height: "35px",
                  marginLeft: "5px",
                  fontSize: "20px",
                  borderRadius: "4px",
                  border: "2px solid rgba(163, 156, 156,0.5 )",
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mt-3">
            <div className="d-flex justify-content-between">
              <button
                className="btn text-primary font-weight-bold cursor-pointer text-right"
                onClick={redirectHomeBtn}
                style={{
                  outline: "none",
                  border: "none",
                  fontWeight: "bolder",
                }}
              >
                Resend otp
              </button>
              <button
                className="btn text-primary font-weight-bold cursor-pointer text-right"
                onClick={verifyOtp}
                style={{
                  outline: "none",
                  border: "none",
                  fontWeight: "bolder",
                }}
              >
                Verify OTP
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
