import * as actions from "../actionTypes/authActionTypes";

export const loginUserState = {
 loginUserData:{},
 eleafUserLoading: false,
};

const authReducer = (state = loginUserState, action) => {
  switch (action.type) {
case actions.SIGNIN_USER_REQUEST:
        return {
          ...state,
          loginUserData: {},
          eleafUserLoading: true,
        };
      case actions.SIGNIN_USER_SUCCESS: {
        let data = action.payload.data;
        return {
          ...state,
          loginUserData: {...data},
          eleafUserLoading: false,
        };
      }
      case actions.SIGNIN_USER_ERROR:
        return {
          ...state,
          eleafUserLoading: false,
        };
    default:
      return state;
  }
  
};

export default authReducer;
