import {
    FETCH_REPORTANALYSISFORMCOUNT_REQUEST,
    FETCH_REPORTANALYSISFORMCOUNT_SUCCESS,
    FETCH_REPORTANALYSISFORMCOUNT_ERROR,
    FETCH_REPORTANALYSISCONSUMERLIST_REQUEST,
    FETCH_REPORTANALYSISCONSUMERLIST_SUCCESS,
    FETCH_REPORTANALYSISCONSUMERLIST_ERROR,
    FETCH_REPORTANALYSISSTAFFLIST_REQUEST,
    FETCH_REPORTANALYSISSTAFFLIST_SUCCESS,
    FETCH_REPORTANALYSISSTAFFLIST_ERROR,
    FETCH_REPORTANALYSISHAZARDLIST_REQUEST,
    FETCH_REPORTANALYSISHAZARDLIST_SUCCESS,
    FETCH_REPORTANALYSISHAZARDLIST_ERROR,
    FETCH_REPORTANALYSISINFECTIONLIST_REQUEST,
    FETCH_REPORTANALYSISINFECTIONLIST_SUCCESS,
    FETCH_REPORTANALYSISINFECTIONLIST_ERROR,
    FETCH_REPORTANALYSISFEEDBACKLIST_REQUEST,
    FETCH_REPORTANALYSISFEEDBACKLIST_SUCCESS,
    FETCH_REPORTANALYSISFEEDBACKLIST_ERROR
     } from "../actionTypes/reportAnalysisActionTypes";
  
  export const userStates = {
                        formsCount:{},
                        reportConsumerList:[],
                        reportStaffList:[],
                        reportHazardList:[],
                        reportInfectionList:[],
                        reportFeedbackList:[]
                      };
  
  const reportAnalysisReducer = (state = userStates, action) => {
    switch (action.type) {
      case FETCH_REPORTANALYSISFORMCOUNT_REQUEST:
          return {
            ...state,
            formsCount:[],
            loading: true,
          };
        case FETCH_REPORTANALYSISFORMCOUNT_SUCCESS:
          let datacul = action.payload.data.data;
          
         return {
            ...state,
            formsCount:{...datacul},
            loading: false,
          };
        case FETCH_REPORTANALYSISFORMCOUNT_ERROR:
          return {
            ...state,
            formsCount:[],
            loading: false,
            error: action.payload.error,
          };
        
          case FETCH_REPORTANALYSISCONSUMERLIST_REQUEST:
          return {
            ...state,
            reportConsumerList:[],
            loading: true,
          };
        case FETCH_REPORTANALYSISCONSUMERLIST_SUCCESS:
          let rlist = action.payload.data.data;
          
         return {
            ...state,
            reportConsumerList:[...rlist],
            loading: false,
          };
        case FETCH_REPORTANALYSISCONSUMERLIST_ERROR:
          return {
            ...state,
            reportConsumerList:[],
            loading: false,
            error: action.payload.error,
          };
        
          case FETCH_REPORTANALYSISSTAFFLIST_REQUEST:
            return {
              ...state,
              reportStaffList:[],
              loading: true,
            };
          case FETCH_REPORTANALYSISSTAFFLIST_SUCCESS:
            let slist = action.payload.data.data;
            
           return {
              ...state,
              reportStaffList:[...slist],
              loading: false,
            };
          case FETCH_REPORTANALYSISSTAFFLIST_ERROR:
            return {
              ...state,
              reportStaffList:[],
              loading: false,
              error: action.payload.error,
            };

            case FETCH_REPORTANALYSISHAZARDLIST_REQUEST:
              return {
                ...state,
                reportHazardList:[],
                loading: true,
              };
            case FETCH_REPORTANALYSISHAZARDLIST_SUCCESS:
              let hlist = action.payload.data.data;
              
             return {
                ...state,
                reportHazardList:[...hlist],
                loading: false,
              };
            case FETCH_REPORTANALYSISHAZARDLIST_ERROR:
              return {
                ...state,
                reportHazardList:[],
                loading: false,
                error: action.payload.error,
              };

              case FETCH_REPORTANALYSISINFECTIONLIST_REQUEST:
                return {
                  ...state,
                  reportInfectionList:[],
                  loading: true,
                };
              case FETCH_REPORTANALYSISINFECTIONLIST_SUCCESS:
                let ilist = action.payload.data.data;
                
               return {
                  ...state,
                  reportInfectionList:[...ilist],
                  loading: false,
                };
              case FETCH_REPORTANALYSISINFECTIONLIST_ERROR:
                return {
                  ...state,
                  reportInfectionList:[],
                  loading: false,
                  error: action.payload.error,
                };

                case FETCH_REPORTANALYSISFEEDBACKLIST_REQUEST:
                  return {
                    ...state,
                    reportFeedbackList:[],
                    loading: true,
                  };
                case FETCH_REPORTANALYSISFEEDBACKLIST_SUCCESS:
                  let feelist = action.payload.data.data;
                  
                 return {
                    ...state,
                    reportFeedbackList:[...feelist],
                    loading: false,
                  };
                case FETCH_REPORTANALYSISFEEDBACKLIST_ERROR:
                  return {
                    ...state,
                    reportFeedbackList:[],
                    loading: false,
                    error: action.payload.error,
                  };
        

      default:
        return state;
    }
  };
  export default reportAnalysisReducer;
  