const AppConsts = {
  authorization: {
    encrptedAuthTokenName: "enc_auth_token",
  },
  appBaseUrl: process.env.REACT_APP_APP_BASE_URL
    ? process.env.REACT_APP_APP_BASE_URL
    : "http://user.eleafsoftware.com.au/",
  remoteServiceBaseUrl: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL
    ? process.env.REACT_APP_REMOTE_SERVICE_BASE_URL
    : "http://admin.eleafsoftware.com.au/",
  // remoteServiceBaseUrl: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL
  //   ? process.env.REACT_APP_REMOTE_SERVICE_BASE_URL
  //   : "http://localhost:5000/",
};
export default AppConsts;
