import React from "react";

import "./App.css";
import Router from "./components/Router";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import tokenAuthService from "./services/tokenAuth/tokenAuthService";
import sessionService from "./services/session/sessionService";
import { socket } from "./Socket";
import Otp from "./screens/otp/Otp";

function App() {
  var token = tokenAuthService.getAccessToken();
  if (!!token) {
    const user = sessionService.getLoggedUserData();
  }
  return (
    <React.Fragment>
      <ToastContainer />
      <Router />
      {/* <Otp /> */}
    </React.Fragment>
  );
}

export default App;
