import * as actions from "../actionTypes/sessionActionTypes";

const sessionReducer = (state = { currentLogin: {} }, action) => {
  switch (action.type) {
    case actions.SET_CURRENT_USER:
      state = action.payload;
      break;
    default:
      state = {};
  }
  return state;
};

export default sessionReducer;
