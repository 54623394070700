import Cookies from "js-cookie";
import http from "../httpService";
import utils from "../../util/utils";
import Otp from "../../screens/otp/Otp";
import { Link, useHistory } from "react-router-dom";
import { message } from "antd";

class TokenAuthService {
  //check if user is authenticated
  isAuthenticated() {
    let data = Cookies.get("access_token");
    if (!data) return false;
    return true;
  }

  getAccessToken = () => Cookies.get("access_token");
  getRefreshToken = () => Cookies.get("refresh_token");

  //login with username and password
  async login(model) {
    console.log("login");
    try {
      const result = await http.post("/api/userlogin", {
        email: model.email,
        password: model.password,
      });
      console.log("status code", result.status);
      if (result.status === 202) {
        // Saving email in cookies
        Cookies.set("twoFactorEmail", model.email, {
          expires: 1,
        });
        // Redirecting user to OTP Page
        window.location = "/user/twofactorauth";
      }

      if (result && result.data && result.data._id) {
        console.log(result.data?.lastLoginFacility, "last login facility");
        const expdate = utils.getExpiryDate();
        // Saving Facility to local Storage
        // SQ JP - 28 August, 23 : checking if last login facility is undefined then saving first facility in local storage
        if (result.data?.lastLoginFacility === undefined) {
          localStorage.setItem(
            "currentFacility",
            result.data?.facility[0].facilityName._id
          );
          // Sandeep/:showed popup that last login facility is selected
          message.info(
            "No last login facility found, your first facility has been selected automatically!"
          );
        } else {
          localStorage.setItem(
            "currentFacility",
            // SQ JP- 18 August, 23 : saving last login facility in local storage
            result.data?.lastLoginFacility
          );
          // Sandeep/:showed popup that last login facility is selected
          message.info(
            "Your last login facility has been selected automatically!"
          );
        }

        Cookies.set("access_token", result.headers["x-auth-token"], {
          expires: expdate,
        });

        Cookies.set("refresh_token", result.headers["x-auth-refresh-token"], {
          expires: expdate,
        });
        return result.data;
      } else {
        console.log("yeh to mazak hai");
      }
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  // SQ JP - 18 July, 23
  // verify OTP
  async verifyOtp(otp) {
    try {
      // get email from cookies
      const email = Cookies.get("twoFactorEmail");
      const result = await http.post("/api/v2/twofactor/verifyotp", {
        email: email,
        otp: otp,
      });
      console.log("status code", result.status);
      console.log("status code", result);

      if (result.status === 200) {
        if (result && result.data && result.data._id) {
          console.log(result.data?.lastLoginFacility, "last login facility");
          const expdate = utils.getExpiryDate();
          // SQ JP - 28 August, 23 : checking if last login facility is undefined then saving first facility in local storage
          if (result.data?.lastLoginFacility === undefined) {
            localStorage.setItem(
              "currentFacility",
              result.data?.facility[0].facilityName._id
            );
            // Sandeep/:showed popup that last login facility is selected
            message.info(
              "No last login facility found, your first facility has been selected automatically!"
            );
          } else {
            localStorage.setItem(
              "currentFacility",
              // SQ JP- 18 August, 23 : saving last login facility in local storage
              result.data?.lastLoginFacility
            );
            // Sandeep/:showed popup that last login facility is selected
            message.info(
              "Your last login facility has been selected automatically!"
            );
          }

          Cookies.set("access_token", result.headers["x-auth-token"], {
            expires: expdate,
          });

          Cookies.set("refresh_token", result.headers["x-auth-refresh-token"], {
            expires: expdate,
          });
          return result.data;
        }
      }
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  //login with gmail
  async logingmail(email) {
    try {
      const result = await http.post("/api/userlogingoogle", {
        email: email,
      });

      if (result && result.data && result.data._id) {
        console.log("yeh to if hi chal rhai hai congooo");
        const expdate = utils.getExpiryDate();
        // SQ JP - 28 August, 23 : checking if last login facility is undefined then saving first facility in local storage
        if (result.data?.lastLoginFacility === undefined) {
          localStorage.setItem(
            "currentFacility",
            result.data?.facility[0].facilityName._id
          );
          // Sandeep/:showed popup that last login facility is selected
          message.info(
            "No last login facility found, your first facility has been selected automatically!"
          );
        } else {
          localStorage.setItem(
            "currentFacility",
            // SQ JP- 18 August, 23 : saving last login facility in local storage
            result.data?.lastLoginFacility
          );
          // Sandeep/:showed popup that last login facility is selected
          message.info(
            "Your last login facility has been selected automatically!"
          );
        }

        Cookies.set("access_token", result.headers["x-auth-token"], {
          expires: expdate,
        });

        Cookies.set("refresh_token", result.headers["x-auth-refresh-token"], {
          expires: expdate,
        });

        return result.data;
      } else {
        console.log("yeh kyu chal kya yarrrrr");
        console.log("Lolzz");
      }
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  //logout - clean all storages n cookies
  logout() {
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
  }

  async forgotPassword(data) {
    try {
      const result = await http
        .post("/api/auth/forgot-password", data)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async resetPassword(data) {
    try {
      const result = await http
        .post("/api/change-password", data)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async validateResetPasswordLink(userid, passresetid) {
    try {
      const result = await http
        .get(`/api/auth/reset-password/${userid}/${passresetid}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
}


// 


export default new TokenAuthService();
