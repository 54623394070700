export const FETCH_HISTORY_REQUEST = 'FETCH_HISTORY_REQUEST';
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_ERROR = 'FETCH_HISTORY_ERROR';

export const FETCH_INDIVISUALFORM_REQUEST = 'FETCH_INDIVISUALFORM_REQUEST';
export const FETCH_INDIVISUALFORM_RESET = 'FETCH_INDIVISUALFORM_RESET';
export const FETCH_INDIVISUALFORM_SUCCESS = 'FETCH_INDIVISUALFORM_SUCCESS';
export const FETCH_INDIVISUALFORM_ERROR = 'FETCH_INDIVISUALFORM_ERROR';

export const FETCH_DRAFTHISTORY_REQUEST = 'FETCH_DRAFTHISTORY_REQUEST';
export const FETCH_DRAFTHISTORY_SUCCESS = 'FETCH_DRAFTHISTORY_SUCCESS';
export const FETCH_DRAFTHISTORY_ERROR = 'FETCH_DRAFTHISTORY_ERROR';

export const DRAFTTOREAL_STAFF_FORM_REQUEST = 'DRAFTTOREAL_STAFF_FORM_REQUEST';
export const DRAFTTOREAL_STAFF_FORM_SUCCESS = 'DRAFTTOREAL_STAFF_FORM_SUCCESS';
export const DRAFTTOREAL_STAFF_FORM_ERROR = 'DRAFTTOREAL_STAFF_FORM_ERROR';

export const DRAFTTOREAL_CONSUMER_FORM_REQUEST = 'DRAFTTOREAL_CONSUMER_FORM_REQUEST';
export const DRAFTTOREAL_CONSUMER_FORM_SUCCESS = 'DRAFTTOREAL_CONSUMER_FORM_SUCCESS';
export const DRAFTTOREAL_CONSUMER_FORM_ERROR = 'DRAFTTOREAL_CONSUMER_FORM_ERROR';

export const DRAFTTOREAL_HAZARD_FORM_REQUEST = 'DRAFTTOREAL_HAZARD_FORM_REQUEST';
export const DRAFTTOREAL_HAZARD_FORM_SUCCESS = 'DRAFTTOREAL_HAZARD_FORM_SUCCESS';
export const DRAFTTOREAL_HAZARD_FORM_ERROR = 'DRAFTTOREAL_HAZARD_FORM_ERROR';

export const DRAFTTOREAL_INFECTION_FORM_REQUEST = 'DRAFTTOREAL_INFECTION_FORM_REQUEST';
export const DRAFTTOREAL_INFECTION_FORM_SUCCESS = 'DRAFTTOREAL_INFECTION_FORM_SUCCESS';
export const DRAFTTOREAL_INFECTION_FORM_ERROR = 'DRAFTTOREAL_INFECTION_FORM_ERROR';

export const DRAFTTOREAL_FEEDBACK_FORM_REQUEST = 'DRAFTTOREAL_FEEDBACK_FORM_REQUEST';
export const DRAFTTOREAL_FEEDBACK_FORM_SUCCESS = 'DRAFTTOREAL_FEEDBACK_FORM_SUCCESS';
export const DRAFTTOREAL_FEEDBACK_FORM_ERROR = 'DRAFTTOREAL_FEEDBACK_FORM_ERROR';
