import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Cookies from 'js-cookie'

const ProtectedRoute = ({ path, component: Component, permission, render, ...rest }) => {
    let data = Cookies.get('access_token')

    return (
        <Route
            {...rest}

            render={props => {
                const isauth = (!data) ? false : true ;

                if (!isauth)
                  return (
                    <Redirect
                      to={{
                        pathname: '/user/login',
                        state: { from: props.location },
                      }}
                    />
                  );

                if (isauth) {
                 return (
                   <Redirect
                       // SQ -JP, 5 Jun, 23 - Showing user forms as default page
                     to={{
                       pathname: '/user/forms',
                       state: { from: props.location },
                     }}
                   />
                 );
                }

                return Component ? <Component {...props} /> : render(props);
            }}
        />
    );
};

export default ProtectedRoute;
