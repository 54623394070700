
import axios from "../httpService";
import { message } from "antd";

class ZohoSsoService {
  //login with username and password
  async loginWithUserNamePassword(email, password) {
    console.log("Zoho Login");
    try {
      const result = await axios.post("/api/zoho/sso-login", {
        email: email,
        password: password,
      });
      console.log("status code", result.status);
      if (result.status === 200) {
        message.info("Login Successful");
        // Redirecting user to OTP Page
        const redirectUrl = result.data.zohoUrl
        window.location = redirectUrl;
      }else{
        message.info("There is an error in Login, please try again!!!! If the problem persists, please contact support@eleafsoftware.com.au");
        console.log("Error in Zoho Login,", result.status);
      }

    } catch (ex) {
      console.log("Error in Zoho Login,", ex.response);
      message.info("There is an unexpected Error, please try again!!!! If the problem persists, please contact support@eleafsoftware.com.au");
    }
  }

}



export default new ZohoSsoService();
