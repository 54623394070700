export const FETCH_USER_DETAILS_REQUEST = "FETCH_USER_DETAILS_REQUEST";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_ERROR = "FETCH_USER_DETAILS_ERROR";
export const UPDATE_USER_DETAILS_REQUEST = "UPDATE_USER_DETAILS_REQUEST";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_ERROR = "UPDATE_USER_DETAILS_ERROR";
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";
export const FETCH_BLAST_USER_REQUEST = "FETCH_BLAST_USER_REQUEST";
export const FETCH_BLAST_USER_SUCCESS = "FETCH_BLAST_USER_SUCCESS";
export const FETCH_BLAST_USER_ERROR = "FETCH_BLAST_USER_ERROR";
export const CREATE_USER_CONFIG_REQUEST = "CREATE_USER_CONFIG_REQUEST";
export const CREATE_USER_CONFIG_SUCCESS = "CREATE_USER_CONFIG_SUCCESS";
export const CREATE_USER_CONFIG_ERROR = "CREATE_USER_CONFIG_ERROR";
export const FETCH_USER_FACILITY_REQUEST = "FETCH_USER_FACILITY_REQUEST";
export const FETCH_USER_FACILITY_SUCCESS = "FETCH_USER_FACILITY_SUCCESS";
export const FETCH_USER_FACILITY_ERROR = "FETCH_USER_FACILITY_ERROR";
export const SET_USER_FACILITY_REQUEST = "SET_USER_FACILITY_REQUEST";
export const SET_USER_FACILITY_SUCCESS = "SET_USER_FACILITY_SUCCESS";
export const SET_USER_FACILITY_ERROR = "SET_USER_FACILITY_ERROR";

export const CONFIUSER_LIST_REQUEST = "CONFIUSER_LIST_REQUEST";
export const CONFIUSER_INACTIVE_LIST_REQUEST =
  "CONFIUSER_INACTIVE_LIST_REQUEST";
export const POLICY_LIST_REQUEST = "POLICY_LIST_REQUEST";
export const POLICY_LIST_SUCCESS = "POLICY_LIST_SUCCESS";
export const POLICY_LIST_ERROR = "POLICY_LIST_ERROR";

export const CONFIUSER_LIST_SUCCESS = "CONFIUSER_LIST_SUCCESS";
export const CONFIUSER_INACTIVE_LIST_SUCCESS =
  "CONFIUSER_INACTIVE_LIST_SUCCESS";

export const CONFIUSER_LIST_ERROR = "CONFIUSER_LIST_ERROR";
export const CONFIUSER_INACTIVE_LIST_ERROR = "CONFIUSER_INACTIVE_LIST_ERROR";

export const UPDATE_USER_CONFIG_REQUEST = "UPDATE_USER_CONFIG_REQUEST";
export const UPDATE_USER_CONFIG_SUCCESS = "UPDATE_USER_CONFIG_SUCCESS";
export const UPDATE_USER_CONFIG_ERROR = "UPDATE_USER_CONFIG_ERROR";
