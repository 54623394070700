export const CREATE_STAFF_FORM_REQUEST = "CREATE_STAFF_FORM_REQUEST";
export const CREATE_STAFF_FORM_SUCCESS = "CREATE_STAFF_FORM_SUCCESS";
export const CREATE_STAFF_FORM_ERROR = "CREATE_STAFF_FORM_ERROR";

export const CREATE_FEEDBACK_FORM_REQUEST = "CREATE_FEEDBACK_FORM_REQUEST";
export const CREATE_FEEDBACK_FORM_SUCCESS = "CREATE_FEEDBACK_FORM_SUCCESS";
export const CREATE_FEEDBACK_FORM_ERROR = "CREATE_FEEDBACK_FORM_ERROR";

export const CREATE_INFECTION_FORM_REQUEST = "CREATE_INFECTION_FORM_REQUEST";
export const CREATE_INFECTION_FORM_SUCCESS = "CREATE_INFECTION_FORM_SUCCESS";
export const CREATE_INFECTION_FORM_ERROR = "CREATE_INFECTION_FORM_ERROR";

export const CREATE_HAZARD_FORM_REQUEST = "CREATE_HAZARD_FORM_REQUEST";
export const CREATE_HAZARD_FORM_SUCCESS = "CREATE_HAZARD_FORM_SUCCESS";
export const CREATE_HAZARD_FORM_ERROR = "CREATE_HAZARD_FORM_ERROR";

export const CREATE_CONINCI_FORM_REQUEST = "CREATE_CONINCI_FORM_REQUEST";
export const CREATE_CONINCI_FORM_SUCCESS = "CREATE_CONINCI_FORM_SUCCESS";
export const CREATE_CONINCI_FORM_ERROR = "CREATE_CONINCI_FORM_ERROR";

export const CREATE_FORMNOTIFICATION_REQUEST =
  "CREATE_FORMNOTIFICATION_REQUEST";
export const CREATE_POLICYPROCEDURE_REQUEST = "CREATE_POLICYPROCEDURE_REQUEST";

export const CREATE_FORMNOTIFICATION_SUCCESS =
  "CREATE_FORMNOTIFICATION_SUCCESS";

export const CREATE_POLICYPROCEDURE_SUCCESS = "CREATE_POLICYPROCEDURE_SUCCESS";

export const CREATE_FORMNOTIFICATION_ERROR = "CREATE_FORMNOTIFICATION_ERROR";
export const CREATE_POLICYPROCEDURE_ERROR = "CREATE_POLICYPROCEDURE_ERROR";

export const CREATE_FORMNOTIFICATIONLIST_REQUEST =
  "CREATE_FORMNOTIFICATIONLIST_REQUEST";
export const CREATE_FORMNOTIFICATIONLIST_SUCCESS =
  "CREATE_FORMNOTIFICATIONLIST_SUCCESS";
export const CREATE_FORMNOTIFICATIONLIST_ERROR =
  "CREATE_FORMNOTIFICATIONLIST_ERROR";

/////////////////////////////////////////////////////////////
export const FETCH_DASHBOARDCONSUMER_REQUEST =
  "FETCH_DASHBOARDCONSUMER_REQUEST";
export const FETCH_DASHBOARDCONSUMER_SUCCESS =
  "FETCH_DASHBOARDCONSUMER_SUCCESS";
export const FETCH_DASHBOARDCONSUMER_ERROR = "FETCH_DASHBOARDCONSUMER_ERROR";

export const FETCH_DASHBOARDSTAFF_REQUEST = "FETCH_DASHBOARDSTAFF_REQUEST";
export const FETCH_DASHBOARDSTAFF_SUCCESS = "FETCH_DASHBOARDSTAFF_SUCCESS";
export const FETCH_DASHBOARDSTAFF_ERROR = "FETCH_DASHBOARDSTAFF_ERROR";

export const FETCH_DASHBOARDHAZARD_REQUEST = "FETCH_DASHBOARDHAZARD_REQUEST";
export const FETCH_DASHBOARDHAZARD_SUCCESS = "FETCH_DASHBOARDHAZARD_SUCCESS";
export const FETCH_DASHBOARDHAZARD_ERROR = "FETCH_DASHBOARDHAZARD_ERROR";

export const FETCH_DASHBOARDINFECTION_REQUEST =
  "FETCH_DASHBOARDINFECTION_REQUEST";
export const FETCH_DASHBOARDINFECTION_SUCCESS =
  "FETCH_DASHBOARDINFECTION_SUCCESS";
export const FETCH_DASHBOARDINFECTION_ERROR = "FETCH_DASHBOARDINFECTION_ERROR";

export const FETCH_DASHBOARDFEEDBACK_REQUEST =
  "FETCH_DASHBOARDFEEDBACK_REQUEST";
export const FETCH_DASHBOARDFEEDBACK_SUCCESS =
  "FETCH_DASHBOARDFEEDBACK_SUCCESS";
export const FETCH_DASHBOARDFEEDBACK_ERROR = "FETCH_DASHBOARDFEEDBACK_ERROR";

export const FETCH_REPORTINJURY_REQUEST = "FETCH_REPORTINJURY_REQUEST";
export const FETCH_REPORTINJURY_SUCCESS = "FETCH_REPORTINJURY_SUCCESS";
export const FETCH_REPORTINJURY_ERROR = "FETCH_REPORTINJURY_ERROR";

export const FETCH_REPORTARL_REQUEST = "FETCH_REPORTARL_REQUEST";
export const FETCH_REPORTARL_SUCCESS = "FETCH_REPORTARL_SUCCESS";
export const FETCH_REPORTARL_ERROR = "FETCH_REPORTARL_ERROR";

export const FETCH_DASHBOARDNOTIFICATION_REQUEST =
  "FETCH_DASHBOARDNOTIFICATION_REQUEST";
export const FETCH_DASHBOARDNOTIFICATION_SUCCESS =
  "FETCH_DASHBOARDNOTIFICATION_SUCCESS";
export const FETCH_DASHBOARDNOTIFICATION_ERROR =
  "FETCH_DASHBOARDNOTIFICATION_ERROR";

export const FETCH_FORMEDITLIST_REQUEST = "FETCH_FORMEDITLIST_REQUEST";
export const FETCH_FORMEDITLIST_SUCCESS = "FETCH_FORMEDITLIST_SUCCESS";
export const FETCH_FORMEDITLIST_ERROR = "FETCH_FORMEDITLIST_ERROR";
