import io from 'socket.io-client';
import AppConsts from '../lib/appconst';
const socket=io.connect(AppConsts.remoteServiceBaseUrl,{transports:['websocket','pooling','flashsocket']});
export function socketConnectionEstablish(){

}
export function socketDisconnected(){

}
//console.log("SOCKET JJJJ",socket)
export {socket};