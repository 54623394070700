import {
  fetchDashboardFeedbackRequest,
  fetchDashboardNotificationError,
} from "../actions/staffFormAction";
import {
  CREATE_STAFF_FORM_REQUEST,
  CREATE_STAFF_FORM_SUCCESS,
  CREATE_STAFF_FORM_ERROR,
  CREATE_FEEDBACK_FORM_REQUEST,
  CREATE_FEEDBACK_FORM_SUCCESS,
  CREATE_FEEDBACK_FORM_ERROR,
  CREATE_INFECTION_FORM_REQUEST,
  CREATE_INFECTION_FORM_SUCCESS,
  CREATE_INFECTION_FORM_ERROR,
  CREATE_HAZARD_FORM_REQUEST,
  CREATE_HAZARD_FORM_SUCCESS,
  CREATE_HAZARD_FORM_ERROR,
  CREATE_CONINCI_FORM_REQUEST,
  CREATE_CONINCI_FORM_SUCCESS,
  CREATE_CONINCI_FORM_ERROR,
  CREATE_FORMNOTIFICATION_REQUEST,
  CREATE_FORMNOTIFICATION_SUCCESS,
  CREATE_FORMNOTIFICATION_ERROR,
  CREATE_FORMNOTIFICATIONLIST_REQUEST,
  CREATE_FORMNOTIFICATIONLIST_SUCCESS,
  CREATE_FORMNOTIFICATIONLIST_ERROR,
  FETCH_DASHBOARDCONSUMER_REQUEST,
  FETCH_DASHBOARDCONSUMER_SUCCESS,
  FETCH_DASHBOARDCONSUMER_ERROR,
  FETCH_DASHBOARDSTAFF_REQUEST,
  FETCH_DASHBOARDSTAFF_SUCCESS,
  FETCH_DASHBOARDSTAFF_ERROR,
  FETCH_DASHBOARDHAZARD_REQUEST,
  FETCH_DASHBOARDHAZARD_SUCCESS,
  FETCH_DASHBOARDHAZARD_ERROR,
  FETCH_DASHBOARDINFECTION_REQUEST,
  FETCH_DASHBOARDINFECTION_SUCCESS,
  FETCH_DASHBOARDINFECTION_ERROR,
  FETCH_DASHBOARDFEEDBACK_REQUEST,
  FETCH_DASHBOARDFEEDBACK_SUCCESS,
  FETCH_DASHBOARDFEEDBACK_ERROR,
  FETCH_REPORTINJURY_REQUEST,
  FETCH_REPORTINJURY_SUCCESS,
  FETCH_REPORTINJURY_ERROR,
  FETCH_REPORTARL_REQUEST,
  FETCH_REPORTARL_SUCCESS,
  FETCH_REPORTARL_ERROR,
  FETCH_DASHBOARDNOTIFICATION_REQUEST,
  FETCH_DASHBOARDNOTIFICATION_SUCCESS,
  FETCH_DASHBOARDNOTIFICATION_ERROR,
  FETCH_FORMEDITLIST_REQUEST,
  FETCH_FORMEDITLIST_SUCCESS,
  FETCH_FORMEDITLIST_ERROR,
} from "../actionTypes/staffFormActionType";
import {
  CREATE_USER_CONFIG_ERROR,
  FETCH_BLAST_USER_ERROR,
} from "../actionTypes/userActionTypes";

export const formsStates = {
  staffForms: [],
  feedbackForms: [],
  infectionForms: [],
  hazardForms: [],
  consumerForms: [],
  formNotification: [],
  formNotificationList: [],
  dashboardConsumer: {},
  dashboardStaff: {},
  dashboardHazard: {},
  dashboardInfection: {},
  dashboardFeedback: {},
  reportInjury: {},
  reportArl: {},
  dashboardNotification: [],
  formeditlist: [],
};

const formsReducer = (state = formsStates, action) => {
  switch (action.type) {
    case CREATE_STAFF_FORM_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
        staffForms: { ...data },
      };
    }
    case CREATE_STAFF_FORM_SUCCESS:
      return {
        ...state,
      };
    case CREATE_STAFF_FORM_ERROR:
      return {
        ...state,
      };

    case CREATE_FEEDBACK_FORM_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
        feedbackForms: { ...data },
      };
    }
    case CREATE_FEEDBACK_FORM_SUCCESS:
      return {
        ...state,
      };
    case CREATE_FEEDBACK_FORM_ERROR:
      return {
        ...state,
      };

    case CREATE_INFECTION_FORM_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
        infectionForms: { ...data },
      };
    }
    case CREATE_INFECTION_FORM_SUCCESS:
      return {
        ...state,
      };
    case CREATE_INFECTION_FORM_ERROR:
      return {
        ...state,
      };

    case CREATE_HAZARD_FORM_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
        hazardForms: { ...data },
      };
    }
    case CREATE_HAZARD_FORM_SUCCESS:
      return {
        ...state,
      };
    case CREATE_HAZARD_FORM_ERROR:
      return {
        ...state,
      };

    case CREATE_CONINCI_FORM_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
        consumerForms: { ...data },
      };
    }
    case CREATE_CONINCI_FORM_SUCCESS:
      return {
        ...state,
      };
    case CREATE_CONINCI_FORM_ERROR:
      return {
        ...state,
      };

    case CREATE_FORMNOTIFICATION_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
        formNotification: { ...data },
      };
    }
    case CREATE_FORMNOTIFICATION_SUCCESS:
      return {
        ...state,
      };
    case CREATE_FORMNOTIFICATION_ERROR:
      return {
        ...state,
      };
    ///////////////////
    case CREATE_FORMNOTIFICATIONLIST_REQUEST:
      return {
        ...state,
        formNotificationList: [],
        loading: true,
      };
    case CREATE_FORMNOTIFICATIONLIST_SUCCESS:
      let datacul = action.payload.data.data;

      return {
        ...state,
        formNotificationList: [...datacul],
        loading: false,
      };
    case CREATE_FORMNOTIFICATIONLIST_ERROR:
      return {
        ...state,
        formNotificationList: [],
        loading: false,
        error: action.payload.error,
      };
    ///////////

    case CREATE_USER_CONFIG_ERROR:
      return {
        ...state,
      };
    ///////////////////////////////
    case FETCH_DASHBOARDCONSUMER_REQUEST:
      return {
        ...state,
        dashboardConsumer: {},
        loading: true,
      };
    case FETCH_DASHBOARDCONSUMER_SUCCESS:
      let dataculdc = action.payload.data.data;

      return {
        ...state,
        dashboardConsumer: { ...dataculdc },
        loading: false,
      };
    case FETCH_DASHBOARDCONSUMER_ERROR:
      return {
        ...state,
        dashboardConsumer: [],
        loading: false,
        error: action.payload.error,
      };

    ///////////////////////////////
    case FETCH_DASHBOARDSTAFF_REQUEST:
      return {
        ...state,
        dashboardStaff: {},
        loading: true,
      };
    case FETCH_DASHBOARDSTAFF_SUCCESS:
      let dataculds = action.payload.data.data;

      return {
        ...state,
        dashboardStaff: { ...dataculds },
        loading: false,
      };
    case FETCH_DASHBOARDSTAFF_ERROR:
      return {
        ...state,
        dashboardStaff: [],
        loading: false,
        error: action.payload.error,
      };

    ///////////////////////////////
    case FETCH_DASHBOARDHAZARD_REQUEST:
      return {
        ...state,
        dashboardHazard: {},
        loading: true,
      };
    case FETCH_DASHBOARDHAZARD_SUCCESS:
      let dataculH = action.payload.data.data;

      return {
        ...state,
        dashboardHazard: { ...dataculH },
        loading: false,
      };
    case FETCH_DASHBOARDHAZARD_ERROR:
      return {
        ...state,
        dashboardHazard: [],
        loading: false,
        error: action.payload.error,
      };

    ///////////////////////////////
    case FETCH_DASHBOARDINFECTION_REQUEST:
      return {
        ...state,
        dashboardInfection: {},
        loading: true,
      };
    case FETCH_DASHBOARDINFECTION_SUCCESS:
      let dataculdi = action.payload.data.data;

      return {
        ...state,
        dashboardInfection: { ...dataculdi },
        loading: false,
      };
    case FETCH_DASHBOARDINFECTION_ERROR:
      return {
        ...state,
        dashboardInfection: [],
        loading: false,
        error: action.payload.error,
      };

    ///////////////////////////////
    case FETCH_DASHBOARDFEEDBACK_REQUEST:
      return {
        ...state,
        dashboardFeedback: {},
        loading: true,
      };
    case FETCH_DASHBOARDFEEDBACK_SUCCESS:
      let dataculdfe = action.payload.data.data;

      return {
        ...state,
        dashboardFeedback: { ...dataculdfe },
        loading: false,
      };
    case FETCH_DASHBOARDFEEDBACK_ERROR:
      return {
        ...state,
        dashboardFeedback: [],
        loading: false,
        error: action.payload.error,
      };

    ///////////////////////////////
    case FETCH_REPORTINJURY_REQUEST:
      return {
        ...state,
        reportInjury: {},
        loading: true,
      };
    case FETCH_REPORTINJURY_SUCCESS:
      let dataculri = action.payload.data.data;

      return {
        ...state,
        reportInjury: { ...dataculri },
        loading: false,
      };
    case FETCH_REPORTINJURY_ERROR:
      return {
        ...state,
        reportInjury: [],
        loading: false,
        error: action.payload.error,
      };

    ///////////////////////////////
    case FETCH_REPORTARL_REQUEST:
      return {
        ...state,
        reportArl: {},
        loading: true,
      };
    case FETCH_REPORTARL_SUCCESS:
      let dataculra = action.payload.data.data;

      return {
        ...state,
        reportArl: { ...dataculra },
        loading: false,
      };
    case FETCH_REPORTINJURY_ERROR:
      return {
        ...state,
        reportArl: [],
        loading: false,
        error: action.payload.error,
      };

    ///////////////////////////////
    case FETCH_DASHBOARDNOTIFICATION_REQUEST:
      return {
        ...state,
        dashboardNotification: [],
        loading: true,
      };
    case FETCH_DASHBOARDNOTIFICATION_SUCCESS:
      let dataculdn = action.payload.data.data;

      return {
        ...state,
        dashboardNotification: [...dataculdn],
        loading: false,
      };
    case FETCH_DASHBOARDNOTIFICATION_ERROR:
      return {
        ...state,
        dashboardNotification: [],
        loading: false,
        error: action.payload.error,
      };
    //////////////////////////////////

    case FETCH_FORMEDITLIST_REQUEST:
      return {
        ...state,
        formeditlist: [],
        loading: true,
      };
    case FETCH_FORMEDITLIST_SUCCESS:
      let formeditdata = action.payload.data.data;

      return {
        ...state,
        formeditlist: [...formeditdata],
        loading: false,
      };
    case FETCH_FORMEDITLIST_ERROR:
      return {
        ...state,
        formeditlist: [],
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};
export default formsReducer;
