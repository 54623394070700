import {
  FETCH_HISTORY_REQUEST,
  FETCH_HISTORY_SUCCESS,
  FETCH_HISTORY_ERROR,
  FETCH_INDIVISUALFORM_REQUEST,
  FETCH_INDIVISUALFORM_SUCCESS,
  FETCH_INDIVISUALFORM_ERROR,
  FETCH_DRAFTHISTORY_REQUEST,
  FETCH_DRAFTHISTORY_SUCCESS,
  FETCH_DRAFTHISTORY_ERROR,
  DRAFTTOREAL_STAFF_FORM_REQUEST,
  DRAFTTOREAL_STAFF_FORM_SUCCESS,
  DRAFTTOREAL_STAFF_FORM_ERROR,
  DRAFTTOREAL_CONSUMER_FORM_REQUEST,
  DRAFTTOREAL_CONSUMER_FORM_SUCCESS,
  DRAFTTOREAL_CONSUMER_FORM_ERROR,
  DRAFTTOREAL_HAZARD_FORM_REQUEST,
  DRAFTTOREAL_HAZARD_FORM_SUCCESS,
  DRAFTTOREAL_HAZARD_FORM_ERROR,
  DRAFTTOREAL_INFECTION_FORM_REQUEST,
  DRAFTTOREAL_INFECTION_FORM_SUCCESS,
  DRAFTTOREAL_INFECTION_FORM_ERROR,
  DRAFTTOREAL_FEEDBACK_FORM_REQUEST,
  DRAFTTOREAL_FEEDBACK_FORM_SUCCESS,
  DRAFTTOREAL_FEEDBACK_FORM_ERROR,
  FETCH_INDIVISUALFORM_RESET,
} from '../actionTypes/historyActionTypes';

export const userStates = {
  formList: [],
  individualFormData: {},
  darftList: [],
  dtorstaffForms: [],
  dtorfeedbackForms: [],
  dtorinfectionForms: [],
  dtorhazardForms: [],
  dtorconsumerForms: [],
};

const historyReducer = (state = userStates, action) => {
  switch (action.type) {
    case FETCH_HISTORY_REQUEST:
      return {
        ...state,
        formList: [],
        loading: true,
      };
    case FETCH_HISTORY_SUCCESS:
      let datacul = action.payload.data.data;
      return {
        ...state,
        formList: [...datacul],
        loading: false,
      };
    case FETCH_HISTORY_ERROR:
      return {
        ...state,
        formList: [],
        loading: false,
        error: action.payload.error,
      };

    case FETCH_DRAFTHISTORY_REQUEST:
      return {
        ...state,
        darftList: [],
        loading: true,
      };
    case FETCH_DRAFTHISTORY_SUCCESS:
      let dataculd = action.payload.data.data;
      return {
        ...state,
        darftList: [...dataculd],
        loading: false,
      };
    case FETCH_DRAFTHISTORY_ERROR:
      return {
        ...state,
        darftList: [],
        loading: false,
        error: action.payload.error,
      };
    case FETCH_INDIVISUALFORM_REQUEST:
      return {
        ...state,
        individualFormData: [],
        loading: true,
      };

    case FETCH_INDIVISUALFORM_RESET:
      return {
        ...state,
        individualFormData: [],
      };
    case FETCH_INDIVISUALFORM_SUCCESS:
      let formData = action.payload.data.data;
      return {
        ...state,
        individualFormData: { ...formData },
        loading: false,
      };
    case FETCH_INDIVISUALFORM_ERROR:
      return {
        ...state,
        individualFormData: [],
        loading: false,
        error: action.payload.error,
      };
    //////////////////////////////////////////////

    case DRAFTTOREAL_STAFF_FORM_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
        dtorstaffForms: { ...data },
      };
    }
    case DRAFTTOREAL_STAFF_FORM_SUCCESS:
      return {
        ...state,
      };
    case DRAFTTOREAL_STAFF_FORM_ERROR:
      return {
        ...state,
      };
    //////////////////////////////////////////////

    case DRAFTTOREAL_CONSUMER_FORM_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
        dtorconsumerForms: { ...data },
      };
    }
    case DRAFTTOREAL_CONSUMER_FORM_SUCCESS:
      return {
        ...state,
      };
    case DRAFTTOREAL_CONSUMER_FORM_ERROR:
      return {
        ...state,
      };
    //////////////////////////////////////////////

    case DRAFTTOREAL_HAZARD_FORM_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
        dtorhazardForms: { ...data },
      };
    }
    case DRAFTTOREAL_HAZARD_FORM_SUCCESS:
      return {
        ...state,
      };
    case DRAFTTOREAL_HAZARD_FORM_ERROR:
      return {
        ...state,
      };
    //////////////////////////////////////////////

    case DRAFTTOREAL_INFECTION_FORM_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
        dtorinfectionForms: { ...data },
      };
    }
    case DRAFTTOREAL_INFECTION_FORM_SUCCESS:
      return {
        ...state,
      };
    case DRAFTTOREAL_INFECTION_FORM_ERROR:
      return {
        ...state,
      };
    //////////////////////////////////////////////

    case DRAFTTOREAL_FEEDBACK_FORM_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
        dtorfeedbackForms: { ...data },
      };
    }
    case DRAFTTOREAL_FEEDBACK_FORM_SUCCESS:
      return {
        ...state,
      };
    case DRAFTTOREAL_FEEDBACK_FORM_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
};
export default historyReducer;
