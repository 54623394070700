import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

class SessionService {
  getLoggedUserData() {
    let token = Cookies.get("access_token");
    const current = jwt_decode(token);
    // console.log("current", current);
    return current;
  }
}

export default new SessionService(); 
