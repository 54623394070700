import asyncComponent from "../../util/asyncComponent";
import {
  INSIGHTPROFILE,
  FORMS,
  REPORTANALYSIS,
  FORMSHISTORY,
  CONFIGURATOR,
  POLICYPROCEDURE,
  REPORTANALYSISBIN,
} from "../../constant/permissionConstant";
export const userRouter = [
  {
    path: "/user",
    component: asyncComponent(() => import("../Layout/UserLayout")),
    isLayout: true,
    showInMenu: false,
  },
  {
    path: "/user/login",
    component: asyncComponent(() => import("../../screens/Login")),
    showInMenu: false,
  },
  {
    path: "/user/twofactorauth",
    component: asyncComponent(() => import("../../screens/otp/Otp")),
    showInMenu: false,
  },
  {
    path: "/:user/forms",
    component: asyncComponent(() => import("../../screens/Forms")),
    showInMenu: true,
    icon: "icon icon-auth-screen",
    role: [FORMS],
    title: "Forms",
  },
  {
    path: "/:user/staffforms/:formtype",
    component: asyncComponent(() =>
      import("../../screens/forms/StaffIncident")
    ),
    showInMenu: false,
    role: [FORMS],
  },
  {
    path: "/:user/consumerforms/:formtype",
    component: asyncComponent(() =>
      import("../../screens/forms/ConsumerIncident")
    ),
    showInMenu: false,
    role: [FORMS],
  },
  {
    path: "/:user/hazardforms/:formtype",
    component: asyncComponent(() => import("../../screens/forms/Hazard")),
    showInMenu: false,
    role: [FORMS],
  },
  {
    path: "/:user/infectionforms/:formtype",
    component: asyncComponent(() => import("../../screens/forms/Infection")),
    showInMenu: false,
    role: [FORMS],
  },
  {
    path: "/:user/feedbackforms/:formtype",
    component: asyncComponent(() => import("../../screens/forms/Feedback")),
    showInMenu: false,
    role: [FORMS],
  },
  {
    path: "/:user/history",
    component: asyncComponent(() => import("../../screens/history")),
    showInMenu: true,
    icon: "icon icon-auth-screen",
    role: [FORMSHISTORY],
    title: "History",
  },
  {
    path: "/:user/staffview/:formtype/:id",
    component: asyncComponent(() =>
      import("../../screens/forms/StaffIncident")
    ),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/consumerview/:formtype/:id",
    component: asyncComponent(() =>
      import("../../screens/forms/ConsumerIncident")
    ),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/hazardview/:formtype/:id",
    component: asyncComponent(() => import("../../screens/forms/Hazard")),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/infectionview/:formtype/:id",
    component: asyncComponent(() => import("../../screens/forms/Infection")),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/feedbackview/:formtype/:id",
    component: asyncComponent(() => import("../../screens/forms/Feedback")),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/user/signup",
    component: asyncComponent(() => import("../../screens/SignUp")),
    showInMenu: false,
  },
  {
    path: "/:user/dashboard",
    component: asyncComponent(() => import("../../screens/Dashboard")),
    showInMenu: true,
    icon: "icon icon-auth-screen",
    role: [INSIGHTPROFILE],
    title: "Insights",
  },
  {
    path: "/:user/profile",
    component: asyncComponent(() => import("../../screens/Profile")),
    showInMenu: false,
  },
  {
    path: "/user/reset-password",
    component: asyncComponent(() => import("../../screens/ResetPaaaword")),
    showInMenu: false,
  },
  {
    path: "/:user/policy-procedure",
    component: asyncComponent(() => import("../../screens/PolicyProcedure")),
    showInMenu: true,
    icon: "icon icon-auth-screen",
    role: [POLICYPROCEDURE],
    title: "Policy Procedure",
  },
  {
    path: "/:user/bin",
    component: asyncComponent(() => import("../../screens/Bin.jsx")),
    showInMenu: true,
    icon: "icon icon-auth-screen",
    role: [REPORTANALYSISBIN],
    title: "Report Analysis Bin",
  },

  {
    path: "/:user/report",
    component: asyncComponent(() => import("../../screens/reportAnalysis")),
    showInMenu: true,
    icon: "icon icon-auth-screen",
    role: [REPORTANALYSIS],
    title: "Report Analysis",
  },
  {
    path: "/:user/configurator",
    component: asyncComponent(() => import("../../screens/configuration")),
    showInMenu: true,
    icon: "icon icon-auth-screen",
    role: [CONFIGURATOR],
    title: "Configuration",
  },
  {
    path: "/:user/consumerreport",
    component: asyncComponent(() =>
      import("../../screens/reportAnalysis/consumerReportAnalysis")
    ),
    showInMenu: false,
    role: [REPORTANALYSIS],
  },
  {
    path: "/:user/staffreport",
    component: asyncComponent(() =>
      import("../../screens/reportAnalysis/staffIncidentReportAnalysis")
    ),
    showInMenu: false,
    role: [REPORTANALYSIS],
  },
  {
    path: "/:user/hazardreport",
    component: asyncComponent(() =>
      import("../../screens/reportAnalysis/hazardReportAnalysis")
    ),
    showInMenu: false,
    role: [REPORTANALYSIS],
  },
  {
    path: "/:user/infectionreport",
    component: asyncComponent(() =>
      import("../../screens/reportAnalysis/infectionReportAnalysis")
    ),
    showInMenu: false,
    role: [REPORTANALYSIS],
  },
  {
    path: "/:user/feedbackreport",
    component: asyncComponent(() =>
      import("../../screens/reportAnalysis/feedbackReportAnalysis")
    ),
    showInMenu: false,
    role: [REPORTANALYSIS],
  },
  {
    path: "/:user/staffreview/:formtype/:id",
    component: asyncComponent(() =>
      import("../../screens/forms/StaffIncident")
    ),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/consumerreview/:formtype/:id",
    // component: asyncComponent(() => import("../../screens/reportAnalysis/consumerReview")),
    component: asyncComponent(() =>
      import("../../screens/forms/ConsumerIncident")
    ),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/hazardreview/:formtype/:id",
    component: asyncComponent(() => import("../../screens/forms/Hazard")),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/infectionreview/:formtype/:id",
    component: asyncComponent(() => import("../../screens/forms/Infection")),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/feedbackreview/:formtype/:id",
    component: asyncComponent(() => import("../../screens/forms/Feedback")),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/staffdraftview/:formtype/:id",
    component: asyncComponent(() =>
      import("../../screens/forms/StaffIncident")
    ),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/consumerdraftview/:formtype/:id",
    component: asyncComponent(() =>
      import("../../screens/forms/ConsumerIncident")
    ),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/hazarddraftview/:formtype/:id",
    component: asyncComponent(() => import("../../screens/forms/Hazard")),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/infectiondraftview/:formtype/:id",
    component: asyncComponent(() => import("../../screens/forms/Infection")),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/feedbackdraftview/:formtype/:id",
    component: asyncComponent(() => import("../../screens/forms/Feedback")),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
  {
    path: "/:user/formeditlist/:id",
    component: asyncComponent(() => import("../../screens/FormsEditList")),
    showInMenu: false,
    role: [FORMSHISTORY],
  },
];

export const appRouters = [
  {
    path: "/",
    exact: true,
    component: asyncComponent(() => import("../Layout/AppLayout")),
    isLayout: true,
    showInMenu: false,
  },

  
  // {
  //   path: "/admin/dashboard",
  //   role: [CLIENTADMIN, SUPERADMIN],
  //   showInMenu: false,
  //   component: asyncComponent(() => import("../../scenes/Admin/Dashboard")),
  // },
];

export const routers = [...userRouter, ...appRouters];
