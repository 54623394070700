import * as actions from "../actionTypes/staffFormActionType";
import FormService from "../../services/form/formService";

//action to set the APIError

export function createStaffFormsRequest(data) {
  return {
    type: actions.CREATE_STAFF_FORM_REQUEST,
    payload: {
      data,
    },
  };
}

export function createStaffFormsSuccess(data) {
  return {
    type: actions.CREATE_STAFF_FORM_SUCCESS,
    payload: {
      data,
    },
  };
}

export function createStaffFormsError(data) {
  return {
    type: actions.CREATE_STAFF_FORM_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export const createStaffForms = (data, config, callback = null) => {
  return async (dispatch) => {
    try {
      dispatch(createStaffFormsRequest(data));
      let result = await FormService.createStaffIncidentForms(data, config);
      dispatch(createStaffFormsSuccess(result));
      if (callback)
        callback({
          success: result.apierror ? false : true,
          message: result.apierror
            ? result.apierror
            : result.formtype === "real"
            ? "Form Submitted Successfully."
            : "Save Draft Successfully",
        });
      return;
    } catch (error) {
      dispatch(createStaffFormsError(error));
      callback({ success: false, message: error.message });
    }
  };
};

export function createFeedbackFormsRequest(data) {
  return {
    type: actions.CREATE_FEEDBACK_FORM_REQUEST,
    payload: {
      data,
    },
  };
}

export function createFeedbackFormsSuccess(data) {
  return {
    type: actions.CREATE_FEEDBACK_FORM_SUCCESS,
    payload: {
      data,
    },
  };
}

export function createFeedbackFormsError(data) {
  return {
    type: actions.CREATE_FEEDBACK_FORM_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export const createFeedbackForms = (data, config, callback = null) => {
  return async (dispatch) => {
    try {
      dispatch(createFeedbackFormsRequest(data));
      let result = await FormService.createFeedbackForms(data, config);
      dispatch(createFeedbackFormsSuccess(result));
      if (callback)
        callback({
          success: result.apierror ? false : true,
          message: result.apierror
            ? result.apierror
            : result.formtype === "real"
            ? "Form Submitted Successfully."
            : "Save Draft Successfully",
        });
      return;
    } catch (error) {
      dispatch(createFeedbackFormsError(error));
      callback({ success: false, message: error.message });
    }
  };
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////

export function createInfectionFormsRequest(data) {
  return {
    type: actions.CREATE_INFECTION_FORM_REQUEST,
    payload: {
      data,
    },
  };
}

export function createInfectionFormsSuccess(data) {
  return {
    type: actions.CREATE_INFECTION_FORM_SUCCESS,
    payload: {
      data,
    },
  };
}

export function createInfectionFormsError(data) {
  return {
    type: actions.CREATE_INFECTION_FORM_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export const createInfectionForms = (data, config, callback = null) => {
  return async (dispatch) => {
    try {
      dispatch(createInfectionFormsRequest(data));
      let result = await FormService.createInfectionForms(data, config);
      dispatch(createInfectionFormsSuccess(result));
      if (callback)
        callback({
          success: result.apierror ? false : true,
          message: result.apierror
            ? result.apierror
            : result.formtype === "real"
            ? "Form Submitted Successfully."
            : "Save Draft Successfully",
        });
      return;
    } catch (error) {
      dispatch(createInfectionFormsError(error));
      callback({ success: false, message: error.message });
    }
  };
};
////////////////////////////////////////////////////////////////////////////////

export function createHazardFormsRequest(data) {
  return {
    type: actions.CREATE_HAZARD_FORM_REQUEST,
    payload: {
      data,
    },
  };
}

export function createHazardFormsSuccess(data) {
  return {
    type: actions.CREATE_HAZARD_FORM_SUCCESS,
    payload: {
      data,
    },
  };
}

export function createHazardFormsError(data) {
  return {
    type: actions.CREATE_HAZARD_FORM_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export const createHazardForms = (data, config, callback = null) => {
  return async (dispatch) => {
    try {
      dispatch(createHazardFormsRequest(data));
      let result = await FormService.createHazardForms(data, config);
      dispatch(createHazardFormsSuccess(result));
      if (callback)
        callback({
          success: result.apierror ? false : true,
          message: result.apierror
            ? result.apierror
            : result.formtype === "real"
            ? "Form Submitted Successfully."
            : "Save Draft Successfully",
        });
      return;
    } catch (error) {
      dispatch(createHazardFormsError(error));
      callback({ success: false, message: error.message });
    }
  };
};
//////////////////////////////////////////////////////////////////
export function createConinciFormsRequest(data) {
  return {
    type: actions.CREATE_CONINCI_FORM_REQUEST,
    payload: {
      data,
    },
  };
}

export function createConinciFormsSuccess(data) {
  return {
    type: actions.CREATE_CONINCI_FORM_SUCCESS,
    payload: {
      data,
    },
  };
}

export function createConinciFormsError(data) {
  return {
    type: actions.CREATE_CONINCI_FORM_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export const createConinciForms = (data, config, callback = null) => {
  return async (dispatch) => {
    try {
      dispatch(createConinciFormsRequest(data));
      let result = await FormService.createConsumerForms(data, config);
      dispatch(createConinciFormsSuccess(result));
      if (callback)
        callback({
          success: result.apierror ? false : true,
          message: result.apierror
            ? result.apierror
            : result.formtype === "real"
            ? "Form Submitted Successfully."
            : "Save Draft Successfully",
        });
      return;
    } catch (error) {
      dispatch(createConinciFormsError(error));
      callback({ success: false, message: error.message });
    }
  };
};
/////////////////////////////////////////////////////////////////

export function createNotiFormUserRequest(data) {
  return {
    type: actions.CREATE_FORMNOTIFICATION_REQUEST,
    payload: {
      data,
    },
  };
}

//Creating policy procedure
export function createPolicyProcedureRequest(data) {
  return {
    type: actions.CREATE_POLICYPROCEDURE_REQUEST,
    payload: {
      data,
    },
  };
}

export function createNotiFormUserSuccess(data) {
  return {
    type: actions.CREATE_FORMNOTIFICATION_SUCCESS,
    payload: {
      data,
    },
  };
}

//Creating policy procedure success

export function createPolicyProcedureSuccess(data) {
  return {
    type: actions.CREATE_POLICYPROCEDURE_SUCCESS,
    payload: {
      data,
    },
  };
}

export function createNotiFormUserError(data) {
  return {
    type: actions.CREATE_FORMNOTIFICATION_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

//Creating policy procedure error

export function createPolicyProcedureError(data) {
  return {
    type: actions.CREATE_POLICYPROCEDURE_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export const createNotiFormUser = (data, callback = null) => {
  return async (dispatch) => {
    try {
      dispatch(createNotiFormUserRequest(data));
      let result = await FormService.createNotificationManager(data);
      dispatch(createNotiFormUserSuccess(result));
      if (callback)
        callback({
          success: result.apierror ? false : true,
          message: result.apierror
            ? result.apierror
            : "Email Added Successfully.",
        });
      return;
    } catch (error) {
      dispatch(createNotiFormUserError(error));
      callback({ success: false, message: error.message });
    }
  };
};

export const createPolicyProcedure = (data, callback = null) => {
  return async (dispatch) => {
    try {
      dispatch(createPolicyProcedureRequest(data));
      let result = await FormService.createPolicyProcedureNotificationManager(
        data
      );
      dispatch(createPolicyProcedureSuccess(result));
      if (callback)
        callback({
          success: result.apierror ? false : true,
          message: result.msg
            ? result.msg
            : "There is an error while adding policy.",
        });
      return;
    } catch (error) {
      dispatch(createPolicyProcedureError(error));
      callback({ success: false, message: error.message });
    }
  };
};
////////////////////////////////////////////////////////////////////////
export function createNotiFormListRequest(data) {
  return {
    type: actions.CREATE_FORMNOTIFICATIONLIST_REQUEST,
    payload: {
      data,
    },
  };
}

export function createNotiFormListSuccess(data) {
  return {
    type: actions.CREATE_FORMNOTIFICATIONLIST_SUCCESS,
    payload: {
      data,
    },
  };
}

export function createNotiFormListError(data) {
  return {
    type: actions.CREATE_FORMNOTIFICATIONLIST_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export const fetchNotiFormList = (fid, cname, formtype) => {
  return async (dispatch) => {
    try {
      dispatch(createNotiFormListRequest(fid, cname));
      let result = await FormService.fetchFormsNotiList(fid, cname, formtype);
      // SQ JP-18 August, 23 : Update Last Login Facility
      let lastLoginFacility = await FormService.updateLastLoginFacilty(fid);
      dispatch(createNotiFormListSuccess(result));
    } catch (error) {
      dispatch(createNotiFormListError(error));
    }
  };
};
/////////////////////////////////////////////////////////
export function fetchDashboardConsumerRequest() {
  return {
    type: actions.FETCH_DASHBOARDCONSUMER_REQUEST,
    payload: {
      data: [],
    },
  };
}

export function fetchDashboardConsumerSuccess(data) {
  return {
    type: actions.FETCH_DASHBOARDCONSUMER_SUCCESS,
    payload: {
      data,
    },
  };
}

export function fetchDashboardConsumerError(data) {
  return {
    type: actions.FETCH_DASHBOARDCONSUMER_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export function dashboardConsumer(startdate, enddate, id, facilityId) {
  return async (dispatch) => {
    try {
      dispatch(fetchDashboardConsumerRequest());
      let result = await FormService.fetchDashboardConsumer(
        startdate,
        enddate,
        id,
        facilityId
      );

      return dispatch(fetchDashboardConsumerSuccess(result));
    } catch (error) {
      dispatch(fetchDashboardConsumerError(error));
    }
  };
}
/////////////////////////

export function fetchDashboardStaffRequest() {
  return {
    type: actions.FETCH_DASHBOARDSTAFF_REQUEST,
    payload: {
      data: [],
    },
  };
}
export function fetchDashboardStaffSuccess(data) {
  return {
    type: actions.FETCH_DASHBOARDSTAFF_SUCCESS,
    payload: {
      data,
    },
  };
}

export function fetchDashboardStaffError(data) {
  return {
    type: actions.FETCH_DASHBOARDSTAFF_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export function dashboardStaff(startdate, enddate, id, facilityId) {
  return async (dispatch) => {
    try {
      dispatch(fetchDashboardStaffRequest());
      let result = await FormService.fetchDashboardStaff(
        startdate,
        enddate,
        id,
        facilityId
      );

      return dispatch(fetchDashboardStaffSuccess(result));
    } catch (error) {
      dispatch(fetchDashboardStaffError(error));
    }
  };
}

/////////////////////////
export function fetchDashboardHazrdRequest() {
  return {
    type: actions.FETCH_DASHBOARDHAZARD_REQUEST,
    payload: {
      data: [],
    },
  };
}
export function fetchDashboardHazrdSuccess(data) {
  return {
    type: actions.FETCH_DASHBOARDHAZARD_SUCCESS,
    payload: {
      data,
    },
  };
}

export function fetchDashboardHazrdError(data) {
  return {
    type: actions.FETCH_DASHBOARDHAZARD_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export function dashboardHazrd(startdate, enddate, id, facilityId) {
  return async (dispatch) => {
    try {
      dispatch(fetchDashboardHazrdRequest());
      let result = await FormService.fetchDashboardHazrd(
        startdate,
        enddate,
        id,
        facilityId
      );

      return dispatch(fetchDashboardHazrdSuccess(result));
    } catch (error) {
      dispatch(fetchDashboardHazrdError(error));
    }
  };
}

/////////////////////////

export function fetchDashboardInfectionRequest() {
  return {
    type: actions.FETCH_DASHBOARDINFECTION_REQUEST,
    payload: {
      data: [],
    },
  };
}
export function fetchDashboardInfectionSuccess(data) {
  return {
    type: actions.FETCH_DASHBOARDINFECTION_SUCCESS,
    payload: {
      data,
    },
  };
}

export function fetchDashboardInfectionError(data) {
  return {
    type: actions.FETCH_DASHBOARDINFECTION_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export function dashboardInfection(startdate, enddate, id,facilityId) {
  return async (dispatch) => {
    try {
      dispatch(fetchDashboardInfectionRequest());
      let result = await FormService.fetchDashboardInfection(
        startdate,
        enddate,
        id,
        facilityId
      );

      return dispatch(fetchDashboardInfectionSuccess(result));
    } catch (error) {
      dispatch(fetchDashboardInfectionError(error));
    }
  };
}

/////////////////////////

export function fetchDashboardFeedbackRequest() {
  return {
    type: actions.FETCH_DASHBOARDFEEDBACK_REQUEST,
    payload: {
      data: [],
    },
  };
}
export function fetchDashboardFeedbackSuccess(data) {
  return {
    type: actions.FETCH_DASHBOARDFEEDBACK_SUCCESS,
    payload: {
      data,
    },
  };
}

export function fetchDashboardFeedbackError(data) {
  return {
    type: actions.FETCH_DASHBOARDFEEDBACK_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export function dashboardFeedback(startdate, enddate, id,facilityId) {
  return async (dispatch) => {
    try {
      dispatch(fetchDashboardFeedbackRequest());
      let result = await FormService.fetchDashboardFeedback(
        startdate,
        enddate,
        id,
        facilityId
      );

      return dispatch(fetchDashboardFeedbackSuccess(result));
    } catch (error) {
      dispatch(fetchDashboardFeedbackError(error));
    }
  };
}
/////////////////////////////

export function fetchReportInjuryRequest() {
  return {
    type: actions.FETCH_REPORTINJURY_REQUEST,
    payload: {
      data: [],
    },
  };
}
export function fetchReportInjurySuccess(data) {
  return {
    type: actions.FETCH_REPORTINJURY_SUCCESS,
    payload: {
      data,
    },
  };
}

export function fetchReportInjuryError(data) {
  return {
    type: actions.FETCH_REPORTINJURY_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export function actionReportInjury(id) {
  return async (dispatch) => {
    try {
      dispatch(fetchReportInjuryRequest());
      let result = await FormService.fetchReportInjury(id);

      return dispatch(fetchReportInjurySuccess(result));
    } catch (error) {
      dispatch(fetchReportInjuryError(error));
    }
  };
}
///////////////////////////

export function fetchReportArlRequest() {
  return {
    type: actions.FETCH_REPORTARL_REQUEST,
    payload: {
      data: [],
    },
  };
}
export function fetchReportArlSuccess(data) {
  return {
    type: actions.FETCH_REPORTARL_SUCCESS,
    payload: {
      data,
    },
  };
}

export function fetchReportArlError(data) {
  return {
    type: actions.FETCH_REPORTARL_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export function actionReportArl(id) {
  return async (dispatch) => {
    try {
      dispatch(fetchReportArlRequest());
      let result = await FormService.fetchReportArl(id);

      return dispatch(fetchReportArlSuccess(result));
    } catch (error) {
      dispatch(fetchReportArlError(error));
    }
  };
}
///////////////////////////////

export function fetchDashboardNotificationRequest() {
  return {
    type: actions.FETCH_DASHBOARDNOTIFICATION_REQUEST,
    payload: {
      data: [],
    },
  };
}
export function fetchDashboardNotificationSuccess(data) {
  return {
    type: actions.FETCH_DASHBOARDNOTIFICATION_SUCCESS,
    payload: {
      data,
    },
  };
}

export function fetchDashboardNotificationError(data) {
  return {
    type: actions.FETCH_DASHBOARDNOTIFICATION_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export function actionDashboardNotification(id) {
  return async (dispatch) => {
    try {
      dispatch(fetchDashboardNotificationRequest());
      let result = await FormService.fetchDashboardNotification(id);

      return dispatch(fetchDashboardNotificationSuccess(result));
    } catch (error) {
      dispatch(fetchDashboardNotificationError(error));
    }
  };
}
//////////////////////////////////////

export function fetchFormEditListRequest() {
  return {
    type: actions.FETCH_FORMEDITLIST_REQUEST,
    payload: {
      data: [],
    },
  };
}
export function fetchFormEditListSuccess(data) {
  return {
    type: actions.FETCH_FORMEDITLIST_SUCCESS,
    payload: {
      data,
    },
  };
}

export function fetchFormEditListError(data) {
  return {
    type: actions.FETCH_FORMEDITLIST_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export function formEditList(id) {
  return async (dispatch) => {
    try {
      dispatch(fetchFormEditListRequest());
      let result = await FormService.fetchFormEditList(id);

      return dispatch(fetchFormEditListSuccess(result));
    } catch (error) {
      dispatch(fetchFormEditListError(error));
    }
  };
}
