import { routers } from "../components/Router/router.config";

import sessionService from "../services/session/sessionService";
import {
    INSIGHTPROFILE,
    FORMS,
    REPORTANALYSIS,
    FORMSHISTORY,
    CONFIGURATOR
} from "../constant/permissionConstant";
class Utils {
  loadScript(url) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    document.body.appendChild(script);
  }

  getLoggedUserRole = async() => {
    const user = sessionService.getLoggedUserData();
    const role =  await user?.permission.map((item)=>item.permissionName);
    return role;
  };

  getRoute = (path) => {
    return routers.filter((route) => route.path === path)[0];
  };

  getPageTitle = (pathname) => {
    const route = routers.filter((route) => route.path === pathname);
    return route[0].title;
  };

  getLoggedUserDashboardURL = () => {
    let dashboard;
    const user = sessionService.getLoggedUserData();
        dashboard = "/:user/dashboard";
    
    return dashboard;
  };


  getExpiryDate = () => {
    var validity_days = 30;
    var expires = validity_days * 1000 * 60 * 60 * 24;
    var tokenExpireDate = new Date(new Date().getTime() + expires);
    return tokenExpireDate;
  };

  generateRandomPassword = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  ObjectId = (m = Math, d = Date, h = 16, s = (s) => m.floor(s).toString(h)) =>
    s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

  removeProtocolFromURL = (url) => {
    if (!url) return;
    let updatedURL = url.replace(/(^\w+:|^)\/\//, "");
    let splittedURLS = updatedURL.split(".");
    if (splittedURLS && splittedURLS.length) {
      if (splittedURLS[0] === "www") {
        return splittedURLS[1];
      } else {
        return splittedURLS[0];
      }
    }
    return "error";
  };

  convertTimeToHMSFormat = (input, separator) => {
    var pad = function (input) {
      return input < 10 ? "0" + input : input;
    };
    const convertTime = [
      Math.floor(input / 3600),
      pad(Math.floor((input % 3600) / 60)),
      pad(Math.floor(input % 60)),
    ].join(typeof separator !== "undefined" ? separator : ":");

    return convertTime;
  };

 
}

export default new Utils();