import * as React from "react";
import { GoogleLogin } from "react-google-login";
import MicrosoftLogin from "react-microsoft-login";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "../assets/css/style.css";
import loginPageLogo from "../assets/images/pages/login.png";
import ZohoSsoService from "../services/tokenAuth/zohoSSOService";
import { userSignIn } from "../store/actions/authAction";
import styles from "./index.module.css";
import { useState } from "react";

const ZohoSSO = () => {
  const [loading,setLoading]=useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = async (values) => {
    values.preventDefault();
    setLoading(true);
    const formData = new FormData(values.target),
      formDataObj = Object.fromEntries(formData.entries());
    await dispatch(
      userSignIn(formDataObj, (res) => {
        if (res.success) {
          toast.success(res.message);
          // SQ -JP, 5 Jun, 23 - Showing user forms as default page
          history.push(
            "/" + res.data.companyName.replace(/\s+/g, "-") + "/forms"
          );
        } else {
          toast.error(res.message);
          history.push("/user/login");
        }
        setLoading(false);
      })
    );
  };

  const onSubmit = (e) => {

    e.preventDefault();
    // Make API call 
    console.log(e.target.email.value, e.target.password.value);
    ZohoSsoService.loginWithUserNamePassword(e.target.email.value, e.target.password.value);
  }

  return (
    <div>
      <title>E-Leaf</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link
        href="https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet"
      />
      <section id="login-page" style={{ height: "100vh" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <img
                className="login-bannere-img"
                src={loginPageLogo}
                alt={"image"}
              />
              <div className="login-div">
                <h2 className="login-h2">Log In</h2>
                <div className="login-socials">
                  <div className="row">
                    <form
                      className="width-100"
                      name="loginForm"
                      onSubmit={onSubmit}
                    >
                      <div className="width-100">
                        <p className="input-lable">Email</p>
                        <input
                          className="input-style"
                          type="email"
                          name="email"
                          required
                        />
                      </div>
                      <div className="width-100">
                        <p
                          className="input-lable"
                          style={{ display: "inline-block" }}
                        >
                          Password
                        </p>
                        <input
                          className="input-style"
                          type="password"
                          name="password"
                          required
                        />
                        <p className="forgot-password">
                          {" "}
                          <Link to={"/user/reset-password"}>
                            Forgot Password?
                          </Link>{" "}
                        </p>
                      </div>
                      <div className="form-button">
                        <button type="submit" disabled={loading}
                          style={{
                            cursor: loading ? "not-allowed" : "pointer",
                            backgroundColor: loading ? "#65b5e8" : "#11A1FD",
                          }}>SIGN IN</button>
                      </div>
                      {/* <p className="bottom-form-text">
                        <a style={{ color: "#A7A7A7" }} href="#">
                          Do not have an account?
                        </a>
                        <Link to={"/user/signup"}>Sign Up</Link>
                      </p> */}
                    </form>
                  </div>
                  <div className="row">
                    <p className="border-text">
                      <span>or</span>
                    </p>
                  </div>
                  <div
                    // className="row"
                    style={{
                      justifyContent: "space-around",
                      paddingBottom: "10%",
                      paddingTop: "5%",
                      marginLeft: "-15px",
                      marginRight: "-15px",
                    }}
                  >
          
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

};

export default ZohoSSO;
