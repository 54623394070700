import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { setInitUrl } from "../../store/actions/authAction";

// import GoogleAnalytics from './GoogleAnalytics';




import ProtectedRoute from "./ProtectedRoute";
import utils from "../../util/utils";
import ZohoSSO from "../../screens/ZohoSSO";
const Router = (props) => {
  const dispatch = useDispatch();

  const { authUser, initURL } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const history = useHistory();

  // useEffect(() => {
  //   let link = document.createElement('link');
  //   link.type = 'text/css';
  //   link.rel = 'stylesheet';
  //   link.href = "/css/style.css";
  //   link.className = 'gx-style';
  //   document.body.appendChild(link);
  // });

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);   
  });
  useEffect(() => {
    if (location.pathname === "/") {
      if (authUser === null) {
        history.push("/user");
      } else if (initURL === "" || initURL === "/") {
        history.push("/user");
      } else {
        history.push(initURL);
      }
    }
  }, [authUser, initURL, location, history]);

  const UserLayout = utils.getRoute("/user").component;
  const AppLayout = utils.getRoute("/").component;

  return (
    <ConfigProvider >
          <Switch>
          {/* SQ JP: 20 September, 23:  */}
          <Route path="/zohosso" component={ZohoSSO} />
            <Route path="/:user" render={(props) => <UserLayout {...props} />} />
            
            <ProtectedRoute
              path="/"
              render={(props) => {

                return <AppLayout {...props} exact />;
              }}
            />
          </Switch>
    </ConfigProvider>
  );
};

export default memo(Router);
