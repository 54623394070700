import axios from "../httpService";

class FormService {
  //get all chapters
  async createStaffIncidentForms(data, config) {
    try {
      const result = await axios
        .post(`/api/forms/staffincident`, data, config)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async createFeedbackForms(data, config) {
    try {
      const result = await axios
        .post(`/api/forms/feedback`, data, config)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async createInfectionForms(data, config) {
    try {
      const result = await axios
        .post(`/api/forms/infection`, data, config)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async createHazardForms(data, config) {
    try {
      const result = await axios
        .post(`/api/forms/hazard`, data, config)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async createConsumerForms(data, config) {
    try {
      const result = await axios
        .post(`/api/forms/consumer`, data, config)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  // Sandeep-SQ changed endpoint /formsnotification to /formsnotification/v1/create
  async createNotificationManager(data) {
    try {
      const result = await axios
        .post(`/api/formsnotification/v1/create`, data)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  //Create policy procedure
  async createPolicyProcedureNotificationManager(data) {
    try {
      const result = await axios.post(`/api/policy/`, data).then((res) => {
        return res.data;
      });
      return result;
    } catch (ex) {
      console.log("yeh javani hai deewani", ex.response.data);
      return { apierror: ex.response.data };
    }
  }

  // SQ JP - 18 August, 23 - Update Last Login Facility
  async updateLastLoginFacilty(id) {
    try {
      const result = await axios
        .post(`/api/facility/updateLastLoginFacilty/${id}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async fetchFormsNotiList(fid, cname, formType) {
    try {
      const result = await axios
        .get(`/api/formsnotification/list/${fid}/${cname}/${formType}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async updateFNotification(id, type) {
    try {
      const result = await axios
        .post(`/api/formsnotification/updatefnotification/${id}/${type}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async updateWNotification(id, type) {
    try {
      const result = await axios
        .post(`/api/formsnotification/updatewnotification/${id}/${type}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async removeFormNotification(id) {
    try {
      const result = await axios
        .post(`/api/formsnotification/delete/${id}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async draftTORealStaffIncidentForms(data, id, config) {
    try {
      const result = await axios
        .post(`/api/forms/updateforms/${id}`, data, config)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async draftTORealConsumerForms(data, id, config) {
    try {
      const result = await axios
        .post(`/api/forms/consumer/drafttoreal/${id}`, data, config)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async draftTORealHazardForms(data, id, config) {
    try {
      const result = await axios
        .post(`/api/forms/hazard/drafttoreal/${id}`, data, config)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async draftTORealInfectionForms(data, id, config) {
    try {
      const result = await axios
        .post(`/api/forms/infection/drafttoreal/${id}`, data, config)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async draftTORealFeedbackForms(data, id, config) {
    try {
      const result = await axios
        .post(`/api/forms/feedback/drafttoreal/${id}`, data, config)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async fetchDashboardConsumer(sdate, edate, id, facilityId) {
    try {
      const result = await axios
        .get(`/api/forms/consumer/getdashboardconsumer/${sdate}/${edate}/${id}/${facilityId}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async fetchDashboardStaff(sdate, edate, id, facilityId) {
    try {
      const result = await axios
        .get(`/api/forms/getdashboardstaff/${sdate}/${edate}/${id}/${facilityId}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async fetchDashboardHazrd(sdate, edate, id,facilityId) {
    try {
      const result = await axios
        .get(`/api/forms/hazard/getdashboardhazard/${sdate}/${edate}/${id}/${facilityId}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async fetchDashboardInfection(sdate, edate, id,facilityId) {
    try {
      const result = await axios
        .get(
          `/api/forms/infection/getdashboardinfection/${sdate}/${edate}/${id}/${facilityId}`
        )
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async fetchDashboardFeedback(sdate, edate, id,facilityId) {
    try {
      const result = await axios
        .get(`/api/forms/feedback/getdashboardfeedback/${sdate}/${edate}/${id}/${facilityId}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async fetchReportInjury(id) {
    try {
      const result = await axios
        .get(`/api/forms/consumer/getreportdata/${id}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  async fetchReportArl(id) {
    // SQ Jp 29 June, 23 - Added new endpoint for ARL report as The pie chart is now replaced with progress indicator widget
    try {
      const result = await axios
        .get(`/api/forms/v2/getreportarl/${id}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async fetchDashboardNotification(id) {
    try {
      const result = await axios
        .get(`/api/forms/hazard/notificationlist/${id}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  async fetchFormEditList(id) {
    try {
      const result = await axios
        .get(`/api/forms/formeditlist/${id}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }
  // Bug ID 4 - Adding API call to delete user
  async deleteUser(data) {
    try {
      const result = await axios
        .post("/api/admin/deleteuser/", data)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  async deletePolicy(data) {
    try {
      const result = await axios
        .delete(`/api/policy/${data?.id}`)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  async deleteConsumerDraftHistory(data) {
    console.log("data", data);
    try {
      const result = await axios
        .post("/api/forms/consumer/delete", data)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  async deleteStaffDraftHistory(data) {
    try {
      const result = await axios
        .post("/api/forms/staffincident/delete", data)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  async deleteHazardDraftHistory(data) {
    try {
      const result = await axios
        .post("/api/forms/hazard/delete", data)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  async deleteInfectionDraftHistory(data) {
    try {
      const result = await axios
        .post("/api/forms/infection/delete", data)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  async deleteFeedbackDraftHistory(data) {
    try {
      const result = await axios
        .post("/api/forms/feedback/delete", data)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  /// Bin deletion functionality
  async binDeleteForms(data) {
    try {
      const result = await axios
        .post("/api/v2/bin/recoverForms/", data)
        .then((res) => res.data);
      return result;
    } catch (ex) {
      return { apierror: ex.response.data };
    }
  }

  // async binDeleteStaffDraftHistory(data) {
  //   try {
  //     const result = await axios
  //       .post("/api/forms/staffincident/delete", data)
  //       .then((res) => res.data);
  //     return result;
  //   } catch (ex) {
  //     return { apierror: ex.response.data };
  //   }
  // }

  // async binDeleteHazardDraftHistory(data) {
  //   try {
  //     const result = await axios
  //       .post("/api/forms/hazard/delete", data)
  //       .then((res) => res.data);
  //     return result;
  //   } catch (ex) {
  //     return { apierror: ex.response.data };
  //   }
  // }

  // async binDeleteInfectionDraftHistory(data) {
  //   try {
  //     const result = await axios
  //       .post("/api/forms/infection/delete", data)
  //       .then((res) => res.data);
  //     return result;
  //   } catch (ex) {
  //     return { apierror: ex.response.data };
  //   }
  // }

  // async binDeleteFeedbackDraftHistory(data) {
  //   try {
  //     const result = await axios
  //       .post("/api/forms/feedback/delete", data)
  //       .then((res) => res.data);
  //     return result;
  //   } catch (ex) {
  //     return { apierror: ex.response.data };
  //   }
  // }
}
export default new FormService();
