import { combineReducers } from "redux";


import Common from "./commonReducer";
import authReducer from "./authReducer";
import sessionReducer from "./sessionReducer";
import formsReducer from './formReducer'
import historyReducer from './historyReducer';
import reportAnalysisReducer from "./reportAnalysisReducer";

import { connectRouter } from "connected-react-router";
import userReducer from "./userReducer";



const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    session: sessionReducer,
    common: Common,
    user: userReducer,
    formsReducer:formsReducer,
    historyReducer:historyReducer,
    reportAnalysisReducer:reportAnalysisReducer
     });

export default reducer;
