import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  FETCH_USER_DETAILS_ERROR,
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_ERROR,
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS,
  FETCH_USER_FACILITY_REQUEST,
  FETCH_USER_FACILITY_SUCCESS,
  FETCH_USER_FACILITY_ERROR,
  SET_USER_FACILITY_REQUEST,
  SET_USER_FACILITY_SUCCESS,
  SET_USER_FACILITY_ERROR,
  CREATE_USER_CONFIG_REQUEST,
  CREATE_USER_CONFIG_SUCCESS,
  CREATE_USER_CONFIG_ERROR,
  CONFIUSER_LIST_REQUEST,
  CONFIUSER_LIST_SUCCESS,
  CONFIUSER_LIST_ERROR,
  CONFIUSER_INACTIVE_LIST_REQUEST,
  CONFIUSER_INACTIVE_LIST_SUCCESS,
  CONFIUSER_INACTIVE_LIST_ERROR,
  UPDATE_USER_CONFIG_REQUEST,
  UPDATE_USER_CONFIG_SUCCESS,
  UPDATE_USER_CONFIG_ERROR,
  POLICY_LIST_SUCCESS,
} from "../actionTypes/userActionTypes";

export const userStates = {
  detail: {},
  dataList: [],
  userFacility: [],
  currentUserFaclity: "",
  newUserDetail: {},
  filteredUsers: [],
  configUserList: [],
  configInactiveUserList: [],
  policyList: [],
  loggedInAsUserName: "",
  blastUsersLoading: false,
};

const userReducer = (state = userStates, action) => {
  switch (action.type) {
    case FETCH_USER_DETAILS_REQUEST:
      return {
        ...state,
        detail: {},
        loading: true,
      };
    case FETCH_USER_DETAILS_SUCCESS:
      let detail = action.payload.data;
      return {
        ...state,
        detail,
        loading: false,
      };
    case FETCH_USER_DETAILS_ERROR:
      return {
        ...state,
        detail: {},
        loading: false,
        error: action.payload.error,
      };
    case CONFIUSER_LIST_REQUEST:
      return {
        ...state,
        configUserList: [],
        loading: true,
      };
    case CONFIUSER_LIST_SUCCESS:
      let datacul = action.payload.data.data;
      return {
        ...state,
        configUserList: [...datacul],
        loading: false,
      };
    case CONFIUSER_INACTIVE_LIST_REQUEST:
      return {
        ...state,
        configInactiveUserList: [],
        loading: true,
      };
    case CONFIUSER_INACTIVE_LIST_SUCCESS:
      let dataInactiveCul = action.payload.data.data;
      return {
        ...state,
        configInactiveUserList: [...dataInactiveCul],
        loading: false,
      };
    case POLICY_LIST_SUCCESS:
      let res = action.payload.data.policies;
      return {
        ...state,
        policyList: [...res],
        loading: false,
      };
    case CONFIUSER_LIST_ERROR:
      return {
        ...state,
        configUserList: [],
        loading: false,
        error: action.payload.error,
      };

    case CONFIUSER_INACTIVE_LIST_ERROR:
      return {
        ...state,
        configInactiveUserList: [],
        loading: false,
        error: action.payload.error,
      };

    case FETCH_USER_FACILITY_REQUEST:
      return {
        ...state,
        userFacility: {},
        loading: true,
      };
    case FETCH_USER_FACILITY_SUCCESS:
      let userFacility = action.payload.data;
      return {
        ...state,
        userFacility,
        loading: false,
      };
    case FETCH_USER_FACILITY_ERROR:
      return {
        ...state,
        userFacility: {},
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      const data = action.payload.data;
      return {
        ...state,
        detail: {
          ...state.detail,
          ...data,
        },
        loading: false,
      };
    case UPDATE_USER_DETAILS_ERROR:
      return {
        ...state,
        detail: {},
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_USER_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_CONFIG_SUCCESS:
      const configdata = action.payload.data;
      return {
        ...state,

        loading: false,
      };
    case UPDATE_USER_CONFIG_ERROR:
      return {
        ...state,
        detail: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_USER_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
        newUserDetail: { ...data },
      };
    }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
      };
    case CREATE_USER_ERROR:
      return {
        ...state,
      };
    case CREATE_USER_CONFIG_REQUEST: {
      const data = action.payload.data;
      return {
        ...state,
      };
    }
    case CREATE_USER_CONFIG_SUCCESS:
      return {
        ...state,
      };
    case CREATE_USER_CONFIG_ERROR:
      return {
        ...state,
      };
    case SET_USER_FACILITY_REQUEST:
      return {
        ...state,
        currentUserFaclity: "",
        loading: true,
      };
    case SET_USER_FACILITY_SUCCESS:
      let currentUserFaclity = action.payload.data;
      return {
        ...state,
        currentUserFaclity,
        loading: false,
      };
    case FETCH_USER_FACILITY_ERROR:
      return {
        ...state,
        currentUserFaclity: "",
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};
export default userReducer;
