import * as actions from "../actionTypes/authActionTypes";
import * as msgactions from "../actionTypes/commonActionTypes";
import tokenAuthService from "../../services/tokenAuth/tokenAuthService";


//action to set the APIError
export function setAPIError(apierror) {
  return {
    type: actions.SET_API_ERROR,
    payload: apierror,
  };
}

export function userSigninRequest(data) {
  return {
    type: actions.SIGNIN_USER_REQUEST,
    payload: {
      data,
    },
  };
}

// SQ JP - 18 July, 23 - OTP Verification
export function userOtpVerifyRequest(data) {
  return {
    type: actions.SIGNIN_USER_REQUEST,
    payload: {
      data,
    },
  };
}

export function userSigninSuccess(data) {
  return {
    type: actions.SIGNIN_USER_SUCCESS,
    payload: {
      data,
    },
  };
}

export function userSigninError(data) {
  return {
    type: actions.SIGNIN_USER_ERROR,
    payload: {
      data: [],
      message: data.message,
    },
  };
}

export const userSignIn = (data, callback = null) => {
  return async (dispatch) => {
    try {
      dispatch(userSigninRequest(data));
      let result = await tokenAuthService.login(data);
      dispatch(userSigninSuccess(result));
      if (callback)
        callback({
          success: result.apierror ? false : true,
          message: result.apierror
            ? result.apierror
            : "Login Successfully.",
            data:result
        });
      return;
    } catch (error) {
      dispatch(userSigninError(error));
      callback({ success: false, message: error.message });
    }
  };
};
export const userSignOut = () => {
  return async (dispatch) => {
    try {
      tokenAuthService.logout();
    } catch (error) {
      throw error;
    }
  };
};

// SQ JP - 18 July, 23
// User is Signed In after OTP Verification
export const userVerifyOtp = (data, callback = null) => {
  return async (dispatch) => {
    try {
     
      dispatch(userSigninRequest(data));
      let result = await tokenAuthService.verifyOtp(data);
      dispatch(userSigninSuccess(result));
      if (callback)
      
        callback({
          success: result.apierror ? false : true,
          message: result.apierror
            ? result.apierror
            : "Login Successfully.",
            data:result
        });
      return;
    } catch (error) {
      dispatch(userSigninError(error));
      callback({ success: false, message: error.message });
    }
  };
};



export const userSignOutSuccess = () => {
  return {
    type: actions.SIGNOUT_USER_SUCCESS,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: msgactions.SHOW_MESSAGE,
    payload: message,
  };
};

export const userGoogleSignIn = () => {
  return {
    type: actions.SIGNIN_GOOGLE_USER,
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: actions.SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser,
  };
};
export const userFacebookSignIn = () => {
  return {
    type: actions.SIGNIN_FACEBOOK_USER,
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: actions.SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser,
  };
};
export const setInitUrl = (url) => {
  return {
    type: actions.INIT_URL,
    payload: url,
  };
};
export const userTwitterSignIn = () => {
  return {
    type: actions.SIGNIN_TWITTER_USER,
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: actions.SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser,
  };
};
export const userGithubSignIn = () => {
  return {
    type: actions.SIGNIN_GITHUB_USER,
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: actions.SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser,
  };
};
export const showAuthLoader = () => {
  return {
    type: msgactions.ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: msgactions.HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: msgactions.ON_HIDE_LOADER,
  };
};
